//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import popconfig from "../config/popconfig";
import Popfield from "./Popfield.vue";

//import qs from "qs";
export default {
  components: {
    "pop-field": Popfield,
  },
  props: {
    popObject: Object,
    readStatus:{
      type:Boolean,
      value:false
    },
    defaultVal: {
      type: Array,
    },
  },
  name: "tablepm",
  data() {
    return {
      showAdd: false,
      isEdit: false,
      currentIndex: "",
      allPosArr: [],
      formData: {
        hhrPosnCode: "", //招聘岗位代码
        hhrPosnCodeMeaning: "", //招聘岗位名称
        hhrNowBjNumber: "", //当前岗位编制数
        hhrNowZbNumber: "", //当前岗位在编人数
        hhrRecruitNumber: "", //招聘人数
        hhrOrgPosnXx: "", //岗位信息
        hhrOrgPosnZz: "", //岗位职责
        hhrOrgPosnYq: "", //任职要求
        hhrWfOrgPerApplicationPosnAttr01: "", //增加编制=招聘人数+在编人数-岗位编制数
      },
    };
  },
  computed: {
    bznum() {
      let tempNum =
        Number(this.formData.hhrNowZbNumber) +
        Number(this.formData.hhrRecruitNumber) -
        Number(this.formData.hhrNowBjNumber);
      return tempNum > 0 ? tempNum : 0;
    },
  },
  created() {
    this.allPosArr = this.defaultVal
    console.log(this.allPosArr)
    Toast.clear();
  },

  methods: {
    showEdit(index) {
      this.formData = this.allPosArr[index];
      this.showAdd = true;
      this.isEdit = true;
      this.currentIndex = index;
    },
    deleteItem(index) {
      this.allPosArr.splice(index, 1);
    },
    addPos() {
      this.formData.hhrWfOrgPerApplicationPosnAttr01 = this.bznum;
      let swapData = JSON.parse(JSON.stringify(this.formData));
      if (this.isEdit) {
        this.allPosArr[this.currentIndex] = swapData;
      } else {
        this.allPosArr.push(swapData);
      }

     

      this.$emit("confirmResult", this.allPosArr);
    },
    initPop() {
      this.ajaxData = JSON.parse(
        JSON.stringify(popconfig[this.popType].ajaxData)
      );
      this.colobj = popconfig[this.popType].colobj;
      this.rule = popconfig[this.popType].rule;
      this.label = this.popLabel
        ? this.popLabel
        : popconfig[this.popType].label;
      this.searchHold = popconfig[this.popType].searchHold;
      this.needSearch = popconfig[this.popType].needSearch;
      this.searchVal = popconfig[this.popType].searchVal;
      this.resultKey = popconfig[this.popType].resultKey;
      if (this.popRequest) {
        this.ajaxData.requestData.requestParamMap.lovCode = this.popRequest;
      }
      if (this.popRule) {
        this.rule = this.popRule;
      }
    },
    popConfirm(obj) {
      console.log(obj);
      this.formData.hhrNowBjNumber = obj.value.obj.actualNumber;
      this.formData.hhrNowZbNumber = obj.value.obj.personNumber;
      this.formData.hhrPosnCodeMeaning = obj.value.text;
      this.formData.hhrPosnCode = obj.value.value;
      console.log(this.formData);
    },
    showAddclick() {
       this.isEdit = false;
      this.formData = {
        hhrPosnCode: "", //招聘岗位代码
        hhrPosnCodeMeaning: "", //招聘岗位名称
        hhrNowBjNumber: "", //当前岗位编制数
        hhrNowZbNumber: "", //当前岗位在编人数
        hhrRecruitNumber: "", //招聘人数
        hhrOrgPosnXx: "", //岗位信息
        hhrOrgPosnZz: "", //岗位职责
        hhrOrgPosnYq: "", //任职要求
        hhrWfOrgPerApplicationPosnAttr01: "", //增加编制=招聘人数+在编人数-岗位编制数
      };
      this.showAdd = true;
    },
    onSave() {
      console.log("sss");
    },
  },
  mounted() {},
};

var popconfig = {
    "hhrCountry": {
        label: '国籍',
        searchHold: '请输入国籍中文名称',
        needSearch: true,
        searchVal: '中国',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/getOrgSqlLovData",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HPFM.COUNTRY",
                    page: "0",
                    size: "10",
                    condition: "中国",
                },
                payload: "",
            },
        },
        colobj: {
            value: "countryCode",
            name: "countryName",
        },
        rule: [{ required: true, message: "" }]
    },
    hhrSex: {
        label: '性别',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.GENDER",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },
    hhrCertificateType: {
        label: '证件类型',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.PER_CERTIFICATE_TYPE",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },
    hhrChannelBig: {
        label: '招聘渠道',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.RECRUIT.B",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: false, message: "" }]
    },
    hhrChannelSmall: {
        label: '招聘分类',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.HTRE_TYPE",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: false, message: "" }]
    },
    hhrFrzt: {
        label: '法人主体',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.PER.HR_RANGE",
                },
                payload: "",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },
    hhrLevel: {
        label: '管理层级',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.EMPLOYEE.SUBGROUP",
                },
                payload: "",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },

    hhrEmpClass: {
        label: '员工类型',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.EMPLOYEE.GROUP",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },
    "hhrPernr": {
        label: '内部推荐人',
        searchHold: '输入推荐人姓名查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/queryPerBiogLovValues",
            requestData: {
                "pathVariableMap": {
                    "tenantId": "0"
                },
                "headerParamMap": {
                },
                "requestParamMap": {
                    "page": "0",
                    "size": "10",
                    "hhrEmpName": "",
                    hhrMustEmpid:'10016205'
                }
                , "payload": "{}"
            },
        },
        colobj: {
            value: "hhrEmpid",
            name: "hhrEmpName",
        },
        rule: [{ required: false, message: "" }]
    },
    "hhrPosnCode": {
        label: '入职岗位',
        searchHold: '输入岗位名称查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/queryPosnLovValues",
            requestData: {
                "pathVariableMap": {
                    "tenantId": "0"
                },
                "headerParamMap": {
                },
                "requestParamMap": {
                    "page": "0",
                    "size": "10",
                    "hhrPosnName": ""
                }
                , "payload": "{}"
            },
        },
        colobj: {
            value: "hhrPosnCode",
            name: "hhrPosnName",
        },
        rule: [{ required: true, message: "" }]
    },
    "hhrRsClass": {
        label: '人事子范围',
        searchHold: '请输入范围名称查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/getOrgSqlLovData",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.PER.HR_SUB_RANGE_L",
                    page: "0",
                    size: "10",
                    condition: "",
                },
                payload: "",
            },
        },
        colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },
    "hhrWorkAddress": {
        label: '工作地点',
        searchHold: '请输入范围名称查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/getOrgSqlLovData",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.WORK_LOC",
                    page: "0",
                    size: "10",
                    condition: "",
                },
                payload: "",
            },
        },
        colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    }, "hhrGzfw": {
        label: '薪资核算范围',
        searchHold: '请输入范围名称查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/getOrgSqlLovData",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.COREHR.PER.SAL_RANGE",
                    page: "0",
                    size: "10",
                    condition: "",
                },
                payload: "",
            },
        },
        colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },


    "processfield": {
        label: '指定顺序流',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogoo/querySpecifiedSequence",
            requestData: {
                pathVariableMap: {
                    organizationId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    processInstanceId: '',
                },
                payload: "",
            },
        },
        colobj: {
            value: "id",
            name: "name",
        },
        rule: [{ required: false, message: "" }]
    },
    bxnCommonDropData: {
        label: '标签',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogooForm/queryLovValueSite",
            requestData: {
                pathVariableMap: {
                    tenantId: "0",
                },
                headerParamMap: {},
                requestParamMap: {
                    lovCode: "HHR.GENDER",
                },
                payload: "{}",
            },
        }, colobj: {
            value: "value",
            name: "meaning",
        },
        rule: [{ required: true, message: "" }]
    },"hhrPersonalPosnCode": {
        label: '入职岗位',
        searchHold: '输入岗位名称查询',
        needSearch: true,
        searchVal: '',
        resultKey: 'content',
        ajaxData: {
            url: "/boogooForm/queryPosnLovValues",
            requestData: {
                "pathVariableMap": {
                    "tenantId": "0"
                },
                "headerParamMap": {
                },
                "requestParamMap": {
                    "page": "0",
                    "size": "10",
                    "hhrPosnName": "",
                     hhrDeptCode:''
                }
                , "payload": "{}"
            }, 
        },
        colobj: {
            value: "hhrPosnCode",
            name: "hhrPosnName",
        },
        rule: [{ required: true, message: "" }]
    },
    hhrWfOrgPerProbationPeriodAttr02: {
        label: '转正类型',
        searchHold: '',
        needSearch: false,
        searchVal: '',
        resultKey: null,
        ajaxData: {
            url: "/boogoo/queryReasonLovValues",
            requestData: {
                "pathVariableMap": {
                    "tenantId": "0"
                },
                "headerParamMap": {
                },
                "requestParamMap": {
                    "hhrActionTypeCode":"PRO"
                },
                "payload":""
            },
        }, colobj: {
            value: "hhrActionReasonCode",
            name: "hhrActionReasonName",
        },
        rule: [{ required: true, message: "" }]
    }


};
export default popconfig;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import popconfig from "../config/popconfig";
//import qs from "qs";
export default {
  props: {
    popType: String,
    popLabel: String,
    popKey: String,
    popRequest: String,
    popRule: Array,
    popObject: Object,
    defaultVal: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  name: "Popfield",
  data() {
    return {
      fieldVal: "",
      label: "国籍",
      rule: [],
      valPicker: false,
      needSearch: false,
      searchVal: "",
      searchHold: "请输入姓名查询",
      pickTitle: "请选择",
      colArr: [],
      colobj: {
        value: "countryCode",
        name: "countryName",
      },
      ajaxData: {
        url: "/boogoo/queryPerBiogLovValues",
        requestData: {},
      },
      resultKey: null,
    };
  },
  created() {
    this.initPop();
    this.fieldVal = this.defaultVal;
  },

  watch: {
    defaultVal(val) {
      this.fieldVal = val;
      if (!this.needSearch) {
        this.swapVal();
      }
    },
  },
  methods: {
    initPop() {
      this.ajaxData = JSON.parse(
        JSON.stringify(popconfig[this.popType].ajaxData)
      );
      this.colobj = popconfig[this.popType].colobj;
      this.rule = popconfig[this.popType].rule;
      this.label = this.popLabel
        ? this.popLabel
        : popconfig[this.popType].label;
      this.searchHold = popconfig[this.popType].searchHold;
      this.needSearch = popconfig[this.popType].needSearch;
      this.searchVal = popconfig[this.popType].searchVal;
      this.resultKey = popconfig[this.popType].resultKey;
      if (this.popRequest) {
        this.ajaxData.requestData.requestParamMap.lovCode = this.popRequest;
      }
      if (this.popRule) {
        this.rule = this.popRule;
      }
    },
    swapVal() {
      this.axios
        .post(this.ajaxData.url, this.ajaxData.requestData)
        .then((res) => {
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              let resultCol = this.resultKey
                ? resultData[this.resultKey]
                : resultData;

              for (var i = 0; i < resultCol.length; i++) {
                if (resultCol[i][this.colobj.value] == this.fieldVal) {
                  this.fieldVal = resultCol[i][this.colobj.name];
                  console.log(this.fieldVal);
                  break;
                }
              }
            }
          }
        });
    },
    showList(reload) {
      if (this.disabled) {
        return;
      }
      if (!reload && this.colArr.length > 0&&this.popType != "hhrPersonalPosnCode") {
        this.valPicker = true;
        return;
      }
      if (this.popType == "hhrPersonalPosnCode") {
        this.ajaxData.requestData.requestParamMap.hhrDeptCode = this.popObject.hhrDeptCode;
      }
      Toast.loading({
        message: "数据加载中...",
        duration: 0,
      });

      this.axios
        .post(this.ajaxData.url, this.ajaxData.requestData)
        .then((res) => {
          console.log("res", res);
          Toast.clear();
          this.valPicker = true;
          if (res && res.data) {
            if (res.data.status == "200") {
              let resultData = JSON.parse(res.data.payload);
              let resultCol = this.resultKey
                ? resultData[this.resultKey]
                : resultData;
              this.buildleaderArr(resultCol);
              console.log(resultCol);
            }
          }
        })
        .catch(function () {
          Toast.clear();
          Toast("请求出错了");
        });
    },
    buildleaderArr(data) {
      console.log("buildleaderArr", data);
      this.colArr = [];
      for (var i = 0; i < data.length; i++) {
        this.colArr.push({
          value: data[i][this.colobj.value],
          text: data[i][this.colobj.name],
          obj: data[i],
        });
      }
    },
    pcikConfirm(value) {
      console.log(value);
      this.valPicker = false;
      this.fieldVal = value.text;
      let key = this.popKey ? this.popKey : this.popType;
      let popData = {
        key: key,
        value: value,
      };
      this.$emit("popconfirm", popData);
    },
    onSearch() {
      switch (this.popType) {
        case "hhrCountry":
        case "hhrRsClass":
          this.ajaxData.requestData.requestParamMap.condition = this.searchVal;
          break;
        case "hhrPernr":
          this.ajaxData.requestData.requestParamMap.hhrEmpName = this.searchVal;
          break;
        case "hhrPosnCode":
        case "hhrPersonalPosnCode":
          this.ajaxData.requestData.requestParamMap.hhrPosnName = this.searchVal;
          break;
      }
      this.showList(true);
    },
  },
  mounted() {},
};
